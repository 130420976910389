import React from 'react';

import './Style.css';

import {Animated} from "react-animated-css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class Item extends React.Component {
    state = {
        selected: false
    }

    render() {
        return (
                <Link style={{ textDecoration: 'none', userSelect:'none' }} to={this.props.path}>
                    <a className={"nav-item" + (window.location.pathname==this.props.path ? " nav-item-selected" : "")}>
                        <div style={{marginTop:"1px"}}>
                            <img src={"/icons/" + this.props.icon} width="32px" height="32px" style={{verticalAlign: "middle", marginTop: "-8px"}} alt={this.props.name+"_logo"}/>
                            {
                                !this.props.collapsed && 
                                    <div style={{display:'inline'}}>
                                        <span className="linking-item" style={{verticalAlign:'top', marginLeft:'10px'}}>{this.props.name}</span>
                                    </div>
                            }
                        </div>
                    </a>
                </Link>
        )
    }
}

export default Item;
