import React from 'react';
import {Animated} from "react-animated-css";

import './Spinner.css';

class Spinner extends React.Component {
    render() {
        return (
            <div class={"spinner" + (this.props.centered ? " center-screen" : "")} style={this.props.noMargin ? {margin: 10, marginLeft: 'auto', marginRight: 'auto'} : {}}>
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        )
    }
}

export default Spinner;