import React from 'react';
import { Animated } from "react-animated-css";

import './Style.css';

import Pill from '../../../Pill';

class TransactionHistory extends React.Component {

    state = {
        open: false
    }

    toggleOpen = () => {
        if(this.props.placeholder) return;
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        return (
            <div className="transaction-history-item">

                <table>
                    <tr onClick={this.toggleOpen}>
                        <td style={{ width: "25%", fontWeight: "bold" }}>
                        {
                                this.props.placeholder 
                                ? <Pill width="70%" height="15px" background="#6f6f6f"/>
                                : this.props.data.product.name
                        }
                        </td>
                        <td style={{ width: "50%", fontWeight: "bold" }}>
                        {
                                this.props.placeholder 
                                ? <Pill width="30%" height="15px" background="#6f6f6f"/>
                                : "Transaction id:"
                        }
                        </td>
                        <td style={{ textAlign: "right", width: "25%", fontWeight: "bold" }}>
                        {
                                this.props.placeholder 
                                ? <Pill width="40%" height="15px" background="#6f6f6f"/>
                                : (
                                    this.props.data.state.completed 
                                    ? "Paid"
                                    : (
                                        this.props.data.state.btcReceived > 0 
                                        ? 'Unconfirmed'
                                        : (
                                            this.props.data.state.expired 
                                            ? 'Expired'
                                            : 'Waiting' 
                                          )
                                      )
                                  )
                        }
                        </td>
                    </tr>
                    <tr onClick={this.toggleOpen}>
                        <td style={{ width: "25%" }}>
                        {
                                this.props.placeholder 
                                ? <Pill width="40%" height="15px" background="#9a9a9a"/>
                                : new Date(this.props.data.state.createdDate).toDateString()
                        }
                        </td>
                        <td style={{ width: "50%" }}>
                        {
                                this.props.placeholder 
                                ? <Pill width="70%" height="15px" background="#9a9a9a"/>
                                : this.props.data.id
                        }
                        </td>
                        <td style={{ textAlign: "right", width: "25%" }}>
                        {
                                this.props.placeholder 
                                ? <Pill width="70%" height="15px" background="#9a9a9a"/>
                                : (this.props.data.price.btc + " BTC")
                        }
                        </td>
                    </tr>
                    {
                        this.state.open 
                            && 
                            <tr style={{marginTop: 20}}>

                                <td>
                                    
                                </td>
                                
                                <td>
                                    <strong>Address</strong><br/>
                                    <span>{this.props.data.address.address} (<a target="_blank" href={this.props.data.address.qrCodeUrl}>qr code</a>)</span>
                                </td>

                                <td style={{textAlign: 'right'}}>
                                    <div>
                                        <span>${this.props.data.price.fiat}</span>
                                    </div>
                                </td>
                            </tr>
                    }
                    {
                        this.state.open &&
                            <tr>
                                <td>
                                <strong>Extra</strong>
                                    <div>
                                        <strong style={{fontSize: 14}}>Invoice </strong>
                                        <span style={{fontSize: 12}}>{this.props.data.extra.invoice}</span>
                                    </div>
                                    <div>
                                        <strong style={{fontSize: 14}}>User </strong>
                                        <span style={{fontSize: 12}}>{this.props.data.extra.user}</span>
                                    </div>
                                    <div>
                                        <strong style={{fontSize: 14}}>User2 </strong>
                                        <span style={{fontSize: 12}}>{this.props.data.extra.user2}</span>
                                    </div>
                                </td>
                            </tr>
                    }
                </table>

            </div>
        )
    }
}

export default TransactionHistory;