import React from 'react';
import { Animated } from "react-animated-css";

import './Style.css';

import Pill from '../../Pill';
import Spinner from '../../Spinner';
import Item from './Item';

import Api from '../../../Scripts/Api';

class HistoryComponent extends React.Component {
    state = {
        loaded: false,
        history: []
    }

    downloadData = async () => {

        this.setState({
            loaded: false
        });
        var data = await Api.vendor.getBalanceHistory(this.props.vendor);

        if (data && !data.error)
            this.setState({
                loaded: true,
                history: data
            });
    }

    componentDidMount() {
        this.downloadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.vendor != this.props.vendor) {
            this.downloadData();
        }
    }

    getHistoryComponents() {
        let items = [];

        for(var i = 0; i < this.state.history.length; i++) {
            items.push(<Item data={this.state.history[i]}/>);
        }

        return <div style={{ maxHeight: '122px', overflowY: 'auto', overflowX: 'hidden'}}>{items}</div>
    }

    render() {
        return (
            <div className="content-container" style={{flexBasis: '64%'}}>
                <div>
                    <h3>Withdrawal history</h3>
                </div>
                {
                    this.state.loaded ?
                        this.state.history.length == 0
                        ? <div><span>No history yet 😢</span></div>
                        : 
                        this.getHistoryComponents()
                    : <Spinner noMargin={true}/>
                }
            </div>
        )
    }
}

export default HistoryComponent;
