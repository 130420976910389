import React from 'react';
import {Animated} from "react-animated-css";

import './Style.css';

import { Line } from 'react-chartjs-2';
import Spinner from '../../Spinner';
import Pill from '../../Pill';
import Api from '../../../Scripts/Api';

class SalesChart extends React.Component {

    chartReference = undefined;
    state = {
        loaded: false
    };

    downloadData = async () => {
        this.setState({
            loaded: false
        });
        
        var data = await Api.vendor.getGraphData(this.props.vendor);
        this.setState({
            loaded: true,
            data: data
        });
    }

    componentDidMount() {
        this.downloadData();
    }
    componentDidUpdate(prevProps) {
        if(prevProps.vendor != this.props.vendor) {
            this.downloadData();
        }
        if(this.chartReference) {
            this.chartReference.chartInstance.resize();
        }
    }

    getLine() {

        var line = <Line ref={(reference) => this.chartReference = reference } options={{ maintainAspectRatio: false, legend: { display: false }, scales: {
            xAxes: [{
                gridLines: {
                    display:true
                }
            }],                    
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                gridLines: {
                    display:false
                }
            }]
        } }} data={this.data}/>

        return line;
    }

    data = (canvas) => {
        return {labels: this.state.data.labels,
            responsive:true,
            datasets: [{
                label: 'BTC Amount',
                data: this.state.data.values,
                borderColor: [
                    '#044bb4',
                ],
                borderWidth: 2
            }]}; 
    }

    render() {
        return (
        <div className="content-container" id="sales-chart-container">

            <div id="sales-month-data" style={{width: '100%'}}>
                <div className="sales-gross-info">
                    <strong>Gross volume</strong>
                    <div className="value">
                        <span>{ this.state.loaded ? this.state.data.volume.crypto.toFixed(6) + ' BTC' : <Pill background="#9a9a9a" height={16} width={80}/>}</span>
                        <span>{ this.state.loaded ? '$' + this.state.data.volume.fiat.toFixed(2) : <Pill background="#adadad" height={12} width={30}/>}</span>
                    </div>
                </div>
                <div className="sales-gross-info">
                    <strong>Average spend per customer</strong>
                    <div className="value">
                        <span>{ this.state.loaded ? this.state.data.customers.average_value_crypto.toFixed(6) + ' BTC' : <Pill background="#9a9a9a" height={16} width={80}/>}</span>
                        <span>{ this.state.loaded ? '$' + this.state.data.customers.average_value_fiat.toFixed(2) : <Pill background="#adadad" height={12} width={30}/>}</span>
                    </div>
                </div>
                <div className="sales-gross-info">
                    <strong>Transactions</strong>
                    <div className="value">
                        <span>{ this.state.loaded ? this.state.data.transactions.total : <Pill background="#9a9a9a" height={16} width={30}/>}</span>
                        <span>{ this.state.loaded ? this.state.data.transactions.total_unique  +' unique users' : <Pill background="#adadad" height={12} width={50}/>}</span>
                    </div>
                </div>

                <div className="sales-gross-info" style={{marginLeft: 'auto', marginRight: 0}}>
                    <strong>Available balance</strong>
                    <div className="value">
                        <span>{ this.state.loaded ? this.state.data.balance.crypto.toFixed(6) + ' BTC' : <Pill background="#9a9a9a" height={16} width={50}/>}</span>
                        <span>{ this.state.loaded ? '$' + this.state.data.balance.fiat.toFixed(2) : <Pill background="#adadad" height={12} width={40}/>}</span>
                    </div>
                </div>
            </div>

            <div style={{height: '100%'}}>
                {
                    this.state.loaded ?
                    (
                    this.getLine()
                    )
                    : <Spinner/>
                }
            </div>
        </div>
        )
    }
}

export default SalesChart;
