import React from 'react';
import {Animated} from "react-animated-css";

import './PageStyle.css';

import History from '../Components/Index/TransactionHistory/Component';

class Index extends React.Component {
  render() {
    return (
      <div className="container-fullscreen">
          <History vendor={this.props.vendor} full={true}/>
      </div>
    )
  }
}

export default Index;
