import React from 'react';
import {Animated} from "react-animated-css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import './Style.css';

import Item from './Item/Component';

import Api from '../../../Scripts/Api';

class TransactionHistory extends React.Component {

    state = {
        loaded: false,
        page: 0
    };

    downloadData = async () => {

        var searchElement = document.getElementById('transaction-history-search-input');
        if(searchElement) {
            searchElement.classList.remove('rounded-input-main-error');
            searchElement.value = "";
        }

        var page = this.state.page;
        this.setState({
            loaded: false
        });
        
        var data = await Api.vendor.getHistory(this.props.vendor, document.getElementById('transaction-history-type-selector').value, page, this.props.full?20:10);
        
        if(data)
            this.setState({
                loaded: true,
                search: false,
                data: data.items,
                page: data.page,
                maxPages: data.maxPages
            });
    }
    searchDownloadData = async () => {

        var value = document.getElementById('transaction-history-search-input').value;
        if(!value || value == '') {
            document.getElementById('transaction-history-search-input').classList.add('rounded-input-main-error')
            return;
        }
        document.getElementById('transaction-history-search-input').classList.remove('rounded-input-main-error');

        this.setState({
            loaded: false
        });

        var data = await Api.vendor.searchHistory(this.props.vendor, document.getElementById('transaction-history-type-selector').value, value); 
        if(data)
            this.setState({
                loaded: true,
                search: true,
                data: data.items,
                page: data.page,
                maxPages: data.maxPages
            });
    }

    typeChanged = () => {
        if(this.state.search) this.searchDownloadData();
        else this.downloadData();
    }

    onPageChange = e => {
        var val = e.target.value;
        this.setState((state, props) => ({
            loaded: false,
            data: state.data,
            page: parseInt(val),
            maxPages: state.maxpages
        }), ()=>{
            this.downloadData();
        });
    }

    componentDidMount() {
        this.downloadData();
    }
    componentDidUpdate(prevProps) {
        if(prevProps.vendor != this.props.vendor) {
            this.downloadData();
        }
    }

    render() {

        var pages = [];
        if(this.state.maxPages) {
            for(var i = 0; i < this.state.maxPages; i++)
                pages.push(i);
        }

        return (
            <div className="content-container" id={"transaction-history-container" + (this.props.full ? "-full" : "")}>
                <div id="transaction-history-control-group">
                    <button className="rounded-button-main" onClick={this.downloadData}>{this.state.search ? "Show All" : "Refresh"}</button>

                    {
                        this.props.full
                        ? (
                            <div style={{display:'inline', marginLeft:'20px'}}>
                                <input className="rounded-input-main" id="transaction-history-search-input" type="text" style={{width:'500px'}} placeholder="Search term by id, bitcoin address, invoice, or user field"/>
                                <button className="outline-button-main" onClick={this.searchDownloadData} style={{marginLeft:'5px'}}>Search</button>
                            </div>
                          )
                        : <span className="text-button-main"><Link style={{textDecoration: 'none', userSelect: 'none', color: '#044bb4'}} to="/transactions">Show all transactions / Search transactions</Link></span>
                    }

                    <select className="rounded-select-main" id="transaction-history-type-selector" onChange={this.typeChanged}>
                        <option value="all">All</option>
                        <option value="completed">Completed</option>
                        <option value="unconfirmed">Unconfirmed</option>
                        <option value="not_completed">Not completed</option>
                    </select>
                </div>

                <div id="transaction-history-item-container">
                    {
                        this.state.loaded 
                        ?
                        this.state.data.map((item, i) => {
                            return (<Item key={'history-item-id-'+i} data={item} placeholder={false}/>);
                        })
                        :
                        (
                            <div>
                                <Item placeholder={true}/>
                                <Item placeholder={true}/>
                                <Item placeholder={true}/>
                            </div>
                        )
                    }
                </div>

                {
                    (this.props.full && !this.state.search) && 
                        <div style={{marginTop: '10px'}}>
                            <select className="rounded-select-main" onChange={this.onPageChange} value={this.state.page}>
                                {
                                    pages.map((item, index) => (
                                        <option value={index}>Page {item + 1}</option>
                                    ))
                                }
                            </select>
                        </div>
                }
        </div>
        )
    }
}

export default TransactionHistory;