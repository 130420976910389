import React from 'react';
import { Animated } from "react-animated-css";

import './Style.css';

class VendorComponent extends React.Component {
    render() {
        return (
            <div className="content-container" style={{flexBasis: '47%'}}>
                <div>
                    <h3>Vendor section</h3>
                </div>
                <div className="withdraw-input-container">
                    <span></span>
                </div>
            </div>
        )
    }
}

export default VendorComponent;
