import React from 'react';
import {Animated} from "react-animated-css";

import './PageStyle.css';

import SalesChart from '../Components/Index/SalesChart/Component';
import TransactionHistory from '../Components/Index/TransactionHistory/Component';
import BalanceSheet from '../Components/Index/BalanceSheet/Component';
import WithdrawOverlay from '../Components/Index/WithdrawOverlay/Component';

class Index extends React.Component {
  state = {
    withdrawOverlay: false
  }

  render() {
    return (
      <div style={{display: "inline-block", flexGrow: '1' }}>
            <div style={{padding:'0 5%'}}>
                <div id="page-information-container">
                    <h1 id="page-title">Dashboard</h1>
                    <p2 id="page-description">In the dashboard you get an overview on your sales and transactions.</p2>
                </div>

                <div id="page-content-container">
                  {
                    this.state.withdrawOverlay &&
                      <WithdrawOverlay vendor={this.props.vendor}/>
                  }
                    <SalesChart sideCollapsed={this.props.sideCollapsed} vendor={this.props.vendor}/>
                    <TransactionHistory vendor={this.props.vendor}/>
                    <BalanceSheet onWithdraw={()=>this.setState({withdrawOverlay: true})} vendor={this.props.vendor}/>
                </div>
            </div>
      </div>
    )
  }
}

export default Index;
