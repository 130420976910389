import React from 'react';
import { Animated } from "react-animated-css";

import './Style.css';

import Api from '../../../Scripts/Api';

class SecurityComponent extends React.Component {
    state = {
        changePasswordVisible: false
    }

    setChangePasswordVisible = () => {
        this.setState({
            changePasswordVisible: true,
            changingPassword: false,
            oldPassword: '',
            newPassword: '',
        });
    }
    
    changePassword = async () => {

        this.setState({
            changingPassword: true
        })

        
        var data = await Api.account.passwordSet(this.state.oldPassword, this.state.newPassword);
        
        if(data.success) {
            this.setState({
                changePasswordVisible: false,
                changingPassword: false
            });
        } else {
            this.setState({
                changingPassword: false
            });
        }
    }

    render() {
        return (
            <div className="content-container" style={{flexBasis: '47%'}}>
                <div>
                    <h3>Account security</h3>
                </div>
                <div className="withdraw-input-container">
                    <div><span>Email: <strong>OnlyQubes@gmail.com</strong></span></div>
                    <div style={{marginTop: 5}}><span>Email confirmed: <strong>Yes</strong></span></div>
                    <div style={{marginTop: 5}}><span>Account created on: <strong>29/12/2020</strong></span></div>
                    <div style={{marginTop: 5}}><span>Password: 
                    {
                        !this.state.changePasswordVisible &&
                            <a href='#' onClick={this.setChangePasswordVisible}>change</a>
                    }
                    </span>
                    {
                        this.state.changePasswordVisible &&
                         <span> 
                             <input type="password" placeholder="Current Password" value={this.state.oldPassword} onChange={(event)=>this.setState({oldPassword:event.target.value})} />
                             <input type="password" placeholder="New Password" value={this.state.newPassword} onChange={(event)=>this.setState({newPassword:event.target.value})} />
                             {
                                 !this.state.changingPassword &&
                                    <button onClick={this.changePassword}>Change</button>
                             }
                         </span>
                    }
                    </div>
                </div>
            </div>
        )
    }
}

export default SecurityComponent;
