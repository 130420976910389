import React from 'react';
import {Animated} from "react-animated-css";
import Cookies from 'universal-cookie';

import './Style.css';

import Item from './Item/Component';

class Navigator extends React.Component {
    
    /*constructor(props) {
        super(props);

        const cookies = new Cookies();
        var sideCollapsed = cookies.get('sideCollapsed');
        this.state = {
            collapsed: sideCollapsed ?? false
        }
    }*/
    state = {collapsed: false}
    
    componentDidMount() {
        const cookies = new Cookies();
        var sideCollapsed = cookies.get('sideCollapsed');
        if(sideCollapsed != undefined)
            this.setState({
                collapsed: sideCollapsed === 'true'
            });
    }

    collapseToggle = () => {
        this.setState((state, props) => ({
            collapsed: !state.collapsed
        }));
        this.props.onCollapseChanged();

        const cookies = new Cookies();
        cookies.set('sideCollapsed', !this.state.collapsed);
    }

    render() {
        return (
        <div id={this.state.collapsed ? "navigator-invisible-margin-collapsed" : "navigator-invisible-margin"}>
            <div id={this.state.collapsed ? "navigator-container-collapsed" : "navigator-container"}>
                <div id="navigator-collapse-container">
                    {
                        !this.state.collapsed &&
                            <select onChange={this.props.onVendorChanged} value={this.props.vendor} id="vendor-selection-option">
                                { 
                                    this.props.vendors.map((item, nr) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                    }
                    <div>
                        <span>{this.state.collapsed ? <img onClick={this.collapseToggle} id="navigator-collapse-clickable" src="/icons/keyboard_arrow_right.svg" alt=">"/> : <img onClick={this.collapseToggle} id="navigator-collapse-clickable" src="/icons/keyboard_arrow_left.svg" alt="<"/>}</span>
                    </div>
                </div>
                <div id="navigator-button-container">
                    <Item icon="home.svg" name="Dashboard" path="/" match={this.props.match} collapsed={this.state.collapsed}/>
                    <Item icon="payment.svg" name="Transactions" path="/transactions" match={this.props.match} collapsed={this.state.collapsed}/>
                    <Item icon="products.svg" name="Products" path="/products" match={this.props.match} collapsed={this.state.collapsed}/>
                    <Item icon="person.svg" name="Account" path="/account" match={this.props.match} collapsed={this.state.collapsed}/>
                </div>
            </div>
        </div>
        )
    }
}

export default Navigator;
