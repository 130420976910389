import React from 'react';
import {Animated} from "react-animated-css";

import './PageStyle.css';

class Index extends React.Component {
  render() {
    return (
      <div style={{display: "inline-block", flexGrow: '1'}}>
          <div style={{display: "flex", height:"100%", alignItems:'center', justifyContent:'center'}}>
              <div style={{textAlign:'center'}}>
                <h1 style={{color:'#444444'}}>In Development 😭</h1>
                <p style={{color:'#8a8a8a'}}>We will notify you once this page is available</p>
              </div>
          </div>
      </div>
    )
  }
}

export default Index;
