const helpers = {
    getUrl: () => 'https://api.blockgate.io/v1/',
    get: async (endpoint) => {
        var result = await fetch(`${helpers.getUrl()}${endpoint}`, { credentials: "include" });
        var data = await result.json();
        
        return helpers.handleResponse(data);
    },
    post: async (endpoint, postData) => {
        var result = await fetch(`${helpers.getUrl()}${endpoint}`, {
            credentials: "include", 
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(postData)
        });
        var data = await result.json();

        return helpers.handleResponse(data);
    },
    handleResponse: (data) => {
        if(!data) return;

        if(data.error && data.error.message)
            alert(data.error.message)
        if(data.message)
            alert(data.message)
        
        if(data.redirect) {
            if(data.redirect.delay) setTimeout(()=> window.location.href = data.redirect.url, data.redirect.delay);
            else window.location.href = data.redirect.url;
        }

        return data.data;
    },

    vendor: {
        payout: (vid, amount, address) => helpers.post('vendor/payout', { amount: amount,address: address, vendorId: vid }),
        getBalance: (vid) => helpers.get(`vendor/${vid}/balance`),
        getBalanceHistory: (vid) => helpers.get(`vendor/${vid}/balance/history`), 
        getAvailableProfiles: () => helpers.get(`vendor/list`),
        getOverviewData: (vid) => helpers.get(`vendor/${vid}/overview`),
        getGraphData: (vid) => helpers.get(`vendor/${vid}/graph`),
        getHistory: (vid, type, page, itemsPerPage) => helpers.get(`vendor/${vid}/history/${type}/${page}/${itemsPerPage}`),
        searchHistory: (vid, type, term) => helpers.post(`vendor/${vid}/history/${type}/0/30`, {searchTerm:term})
    },

    account: {
        passwordSet: (oldPassword, newPassword) => helpers.post('account/password/set', { oldPassword: oldPassword, newPassword: newPassword }),
    }
}

export default helpers;