import React from 'react';
import {Animated} from "react-animated-css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import './Style.css';

class WithdrawOverlay extends React.Component {

    state = {
        amount: '0',
        address: ''
    }

    withdrawMoney = async () => {
        var address = this.state.address;
        var amount = this.state.amount;
        if(!address || address.length < 8 || address.length > 256) return alert('Error: invalid address');
        if(!amount) return alert('Error: invalid amount');
        
        try {
            amount = parseFloat(amount);
        } catch {
            return alert('Error: invalid amount')
        }

        var result = await fetch(`https://api.blockgate.io/v1/vendor/payout/`, {
            credentials: "include", 
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({
                amount: amount,
                address: address,
                vendorId: this.props.vendor
            })
        });
        alert(JSON.stringify(result));
    }

    render() {
        return (
        <div className="overlay-container">
            <div className="overlay-form">
                <div className="overlay-form-header">
                    <h2>Withdraw</h2>
                </div>
                <div style={{marginTop: 20}}>
                    <div>
                        <span>Amount to withdraw (max <a href="#">0.00</a>)</span>
                        <br/>
                        <input type="text" value={this.state.amount} onChange={(event)=>this.setState({amount:event.target.value})} />
                    </div>
                    <div style={{marginTop: 10}}>
                        <span>Address to withdraw to</span>
                        <br/>
                        <input type="text" value={this.state.address} onChange={(event)=>this.setState({address:event.target.value})} />
                    </div>
                </div>
                <div style={{marginTop: 20}}>
                    <button onClick={this.withdrawMoney} className="rounded-button-main">Confirm</button>
                </div>
            </div>
        </div>
        )
    }
}

export default WithdrawOverlay;