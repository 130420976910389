import React from 'react';
import { Animated } from "react-animated-css";

import './Style.css';

import { Line } from 'react-chartjs-2';
import Pill from '../../Pill';
import Spinner from '../../Spinner';

import Api from '../../../Scripts/Api';

class BalanceSheet extends React.Component {
    state = {
        loaded: false
    }

    downloadData = async () => {

        this.setState({
            loaded: false
        });
        
        var data = await Api.vendor.getOverviewData(this.props.vendor);
        if (data && !data.error)
            this.setState({
                loaded: true,
                data: data
            });
    }

    componentDidMount() {
        this.downloadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.vendor != this.props.vendor) {
            this.downloadData();
        }
    }

    render() {
        return (
            <div className="content-container" id="balance-sheet-container">
                <div className="content-container balance-item">
                    <div>
                        <strong>Spend per customer</strong><br />
                        <div className="value">
                            <span>{this.state.loaded ? this.state.data.customer.spend.total_crypto.toFixed(8) + ' BTC' : <Pill background="#9a9a9a" height={16} width={80} />}</span>
                            <span>{this.state.loaded ? '$' + this.state.data.customer.spend.total_fiat.toFixed(2) : <Pill background="#adadad" height={13} width={30} />}</span>
                        </div>
                        <div>
                            {
                                this.state.loaded ?
                                    (
                                        <div style={{ height: 65, width: '100%' }}>
                                            <Line ref={(reference) => this.chartReference = reference} options={{
                                                maintainAspectRatio: false, legend: { display: false }, scales: {
                                                    xAxes: [{
                                                        ticks: {
                                                            display: false
                                                        },
                                                        gridLines: {
                                                            display: true
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        display: false,
                                                        ticks: {
                                                            beginAtZero: true
                                                        },
                                                        gridLines: {
                                                            display: false
                                                        },
                                                        labels: {
                                                            display: false
                                                        }
                                                    }]
                                                }
                                            }} data={{
                                                labels: this.state.data.customer.spend.labels,
                                                responsive: true,
                                                datasets: [{
                                                    label: 'BTC Amount',
                                                    data: this.state.data.customer.spend.values,
                                                    backgroundColor: [
                                                        '#044bb433',
                                                    ],
                                                    borderColor: [
                                                        '#044bb4',
                                                    ],
                                                    borderWidth: 2
                                                }]
                                            }} />
                                        </div>
                                    )
                                    : <Spinner noMargin={true} />
                            }
                        </div>
                    </div>
                </div>
                <div className="content-container balance-item">
                    <div>
                        <strong>Gross volume for past year</strong><br />
                        <div className="value">
                            <span>{this.state.loaded ? this.state.data.volume.year.total_crypto.toFixed(8) + ' BTC' : <Pill background="#9a9a9a" height={16} width={80} />}</span>
                            <span>{this.state.loaded ? '$' + this.state.data.volume.year.total_fiat.toFixed(2) : <Pill background="#adadad" height={13} width={30} />}</span>
                        </div>
                        <div>
                            {
                                this.state.loaded ?
                                    (
                                        <div style={{ height: 65, width: '100%' }}>
                                            <Line ref={(reference) => this.chartReference = reference} options={{
                                                elements: {
                                                    point: {
                                                        radius: 0
                                                    }
                                                },
                                                maintainAspectRatio: false, legend: { display: false }, scales: {
                                                    xAxes: [{
                                                        display: false,
                                                        ticks: {
                                                            display: false
                                                        },
                                                        gridLines: {
                                                            display: true
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        display: false,
                                                        ticks: {
                                                            beginAtZero: true
                                                        },
                                                        gridLines: {
                                                            display: false
                                                        },
                                                        labels: {
                                                            display: false
                                                        }
                                                    }]
                                                }
                                            }} data={{
                                                labels: this.state.data.volume.year.labels,
                                                responsive: true,
                                                datasets: [{
                                                    label: 'BTC Amount',
                                                    data: this.state.data.volume.year.values,
                                                    backgroundColor: [
                                                        '#044bb433',
                                                    ],
                                                    borderColor: [
                                                        '#044bb4',
                                                    ],
                                                    borderWidth: 2
                                                }]
                                            }} />
                                        </div>
                                    )
                                    : <Spinner noMargin={true} />
                            }
                        </div>
                    </div>
                </div>
                <div className="content-container balance-item">
                    <div>
                        <strong>Customers for past month</strong><br />
                        <div className="value">
                            <span>{this.state.loaded ? this.state.data.customer.count_month.total : <Pill background="#9a9a9a" height={16} width={80} />}</span>
                        </div>
                        <div>
                            {
                                this.state.loaded ?
                                    (
                                        <div style={{ height: 65, width: '100%' }}>
                                            <Line ref={(reference) => this.chartReference = reference} options={{
                                                maintainAspectRatio: false, legend: { display: false }, scales: {
                                                    xAxes: [{
                                                        ticks: {
                                                            display: false
                                                        },
                                                        gridLines: {
                                                            display: true
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        display: false,
                                                        ticks: {
                                                            beginAtZero: true
                                                        },
                                                        gridLines: {
                                                            display: false
                                                        },
                                                        labels: {
                                                            display: false
                                                        }
                                                    }]
                                                }
                                            }} data={{
                                                labels: this.state.data.customer.count_month.labels,
                                                responsive: true,
                                                datasets: [{
                                                    label: 'customers',
                                                    data: this.state.data.customer.count_month.values,
                                                    backgroundColor: [
                                                        '#044bb433',
                                                    ],
                                                    borderColor: [
                                                        '#044bb4',
                                                    ],
                                                    borderWidth: 2
                                                }]
                                            }} />
                                        </div>
                                    )
                                    : <Spinner noMargin={true} />
                            }
                        </div>
                    </div>
                </div>
                <div className="content-container balance-item">
                    <div>
                        <strong>Customers for past year</strong><br />
                        <div className="value">
                            <span>{this.state.loaded ? this.state.data.customer.count_year.total : <Pill background="#9a9a9a" height={16} width={80} />}</span>
                        </div>
                        <div>
                            {
                                this.state.loaded ?
                                    (
                                        <div style={{ height: 65, width: '100%' }}>
                                            <Line ref={(reference) => this.chartReference = reference} options={{
                                                elements: {
                                                    point: {
                                                        radius: 0
                                                    }
                                                },
                                                maintainAspectRatio: false, legend: { display: false }, scales: {
                                                    xAxes: [{
                                                        display: false,
                                                        ticks: {
                                                            display: false
                                                        },
                                                        gridLines: {
                                                            display: true
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        display: false,
                                                        ticks: {
                                                            beginAtZero: true
                                                        },
                                                        gridLines: {
                                                            display: false
                                                        },
                                                        labels: {
                                                            display: false
                                                        }
                                                    }]
                                                }
                                            }} data={{
                                                labels: this.state.data.customer.count_year.labels,
                                                responsive: true,
                                                datasets: [{
                                                    label: 'customers',
                                                    data: this.state.data.customer.count_year.values,
                                                    backgroundColor: [
                                                        '#044bb433',
                                                    ],
                                                    borderColor: [
                                                        '#044bb4',
                                                    ],
                                                    borderWidth: 2
                                                }]
                                            }} />
                                        </div>
                                    )
                                    : <Spinner noMargin={true} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BalanceSheet;
