import React from 'react';
import {Animated} from "react-animated-css";

class Pill extends React.Component {
    render() {
        return (
        <div style={{height:this.props.height, width: this.props.width, borderRadius: "5px", background: this.props.background, display: "inline-block"}}>
            
        </div>
        )
    }
}

export default Pill;
