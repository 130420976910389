import React from 'react';
import { Animated } from "react-animated-css";

import './Style.css';

import Pill from '../../Pill';
import Spinner from '../../Spinner';
import Api from '../../../Scripts/Api';

class WithdrawComponent extends React.Component {
    state = {
        loaded: false,
        address: '',
        amount: '0.00001',
        transferringBtc: false
    }

    withdrawMoney = async () => {
        if(this.state.transferringBtc) return;
        var address = this.state.address;
        var amount = this.state.amount;
        if(!address || address.length < 8 || address.length > 256) return alert('Error: invalid address');
        if(!amount) return alert('Error: invalid amount');
        
        try {
            amount = parseFloat(amount);
        } catch {
            return alert('Error: invalid amount')
        }

        this.setState({
            transferringBtc: true
        });

        var data = await Api.vendor.payout(this.props.vendor, amount, address);
        if(!data.error) this.downloadData();
        
        this.setState({
            transferringBtc: false
        });
    }

    downloadData = async () => {

        this.setState({
            loaded: false
        });
        
        var data = await Api.vendor.getBalance(this.props.vendor);

        if (data && !data.error)
            this.setState({
                loaded: true,
                data: data
            });
    }

    componentDidMount() {
        this.downloadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.vendor != this.props.vendor) {
            this.downloadData();
        }
    }

    render() {
        return (
            <div className="content-container" style={{flexBasis: '30%'}}>
                <div>
                    <h3>Balance withdrawal</h3>
                </div>
                <div>
                    <span><strong style={{fontSize: 24}}>{this.state.loaded ? (this.state.data.availableBtc-0.00046).toFixed(8) : <Pill background="#9a9a9a" height={24} width={100} />}</strong> BTC available</span><br/>
                    <span style={{opacity: 0.7}}>${this.state.loaded ? this.state.data.availableUsd.toFixed(2) : <Pill background="#adadad" height={14} width={40} />}</span>
                </div>
                <div className="withdraw-input-container">
                    <input className="withdraw-input" type="text" placeholder="BTC Address" value={this.state.address} onChange={(event)=>this.setState({address:event.target.value})} />
                    <input className="withdraw-input" type="number" max={this.state.loaded ? (this.state.data.availableBtc-0.00046).toFixed(8) : 10} min="0.00001" placeholder="Amount" value={this.state.amount} onChange={(event)=>this.setState({amount:event.target.value})} />
                    <button className="rounded-button-main" disabled={this.state.transferringBtc} onClick={this.withdrawMoney}>Withdraw</button><br/>
                    <span style={{opacity: 0.7, fontSize: 12}}>0.0004 BTC fee</span>
                </div>
            </div>
        )
    }
}

export default WithdrawComponent;
