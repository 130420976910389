import React from 'react';
import { Animated } from "react-animated-css";

import './PageStyle.css';
import WithdrawComponent from '../Components/Account/Withdraw/Component';
import HistoryComponent from '../Components/Account/History/Component';
import SecurityComponent from '../Components/Account/Security/Component';
import VendorComponent from '../Components/Account/Vendor/Component';

class Account extends React.Component {
  render() {
    return (
      <div style={{ display: "inline-block", flexGrow: '1' }}>
        <div style={{ padding: '0 5%' }}>
          <div id="page-information-container">
            <h1 id="page-title">Account</h1>
            <p2 id="page-description">Here you can manage your account settings and funds.</p2>
          </div>

          <div id="page-content-container">
            <WithdrawComponent vendor={this.props.vendor} />
            <HistoryComponent  vendor={this.props.vendor} />
            <SecurityComponent vendor={this.props.vendor} />
            <VendorComponent vendor={this.props.vendor} />
          </div>
        </div>
      </div>
    )
  }
}

export default Account;
