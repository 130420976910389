import React from 'react';
import { Animated } from "react-animated-css";

import './Style.css';

import Pill from '../../Pill';
class HistoryItemComponent extends React.Component {
    render() {
        return (
            <div className="history-item">
                <span>{new Date(this.props.data.date).toLocaleDateString() + ' ' + new Date(this.props.data.date).toLocaleTimeString()}</span>
                <span>{this.props.data.amount + ' ' + this.props.data.currency}</span>
                <span>sent to address {this.props.data.address}</span>
            </div>
        )
    }
}

export default HistoryItemComponent;
