import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import Cookies from 'universal-cookie';

import Spinner from '../Components/Spinner';

import Index from './Index';
import Transactions from './Transactions';
import InDev from './Dev';
import Account from './Account';
import Navigator from '../Components/Navigator/Component';

import Api from '../Scripts/Api';

class App extends React.Component {

    state = {
        loaded: false
    }

    downloadData = async () => {
        this.setState({
            loaded: false,
        });
        var data = await Api.vendor.getAvailableProfiles();

        const cookies = new Cookies();
        var defaultVendor = cookies.get('defaultVendor') || 0;

        var currentVendor;
        if(data.length >= defaultVendor) currentVendor = data[defaultVendor].id;

        this.setState((state, props) => ({
            loaded: true,
            vendor: state.vendor || currentVendor,
            vendors: data
        }));
    }

    onVendorChanged = () => {
        
        var value = document.getElementById('vendor-selection-option').value;
        this.setState((state, props) => ({
            loaded: state.loaded,
            vendor: value,
            vendors: state.vendors
        }));

        const cookies = new Cookies();
        for(var i = 0; i < this.state.vendors.length; i++) {
            if(this.state.vendors[i].id == value){
                cookies.set('defaultVendor', i);
            }
        }
    }

    onCollapseChanged = () => {
        this.setState((state, props) => ({
            loaded: state.loaded,
            vendor: state.vendor,
            vendors: state.vendors,
            sideCollapsed: true
        }));
    }

    componentDidMount() {
        this.downloadData();
    }

    render() { 
        const NavComponentRouted = withRouter(props => <Navigator {...props} onCollapseChanged={this.onCollapseChanged} onVendorChanged={this.onVendorChanged} vendor={this.state.vendor} vendors={this.state.vendors}/>)
        return (
            <div>

            { this.state.loaded 
                ? <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Router>
                        <NavComponentRouted/>
                        <Switch>
                            <Route path="/products">
                                <InDev vendor={this.state.vendor}/>
                            </Route>
                            <Route path="/account">
                                <Account vendor={this.state.vendor} vendor={this.state.vendor}/>
                            </Route>
                            <Route path="/transactions">
                                <Transactions sideCollapsed={this.state.sideCollapsed} vendor={this.state.vendor}/>
                            </Route>
                            <Route path="/">
                                <Index sideCollapsed={this.state.sideCollapsed} vendor={this.state.vendor}/>
                            </Route>
                        </Switch>
                    </Router>
                 </div>
                : <Spinner centered={true}/>
            }

            </div>
        )
    }
}

export default App;
